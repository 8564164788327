import React, { useState } from 'react'
import SimpleCalendar from './SimpleCalendar'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const ChatCalendar = ({ reserves, handleEventClick }) => {
  const [selectEvent, setSelectEvent] = useState({})

  return (
    <>
      <SimpleCalendar
        events={reserves}
        handleEventClick={handleEventClick}
      />
    </>
  )
}

export default ChatCalendar
