import React, { useState } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const SimpleReserveEvents = ({ events, handleEventClick, activeDate }) => {
  return (
    <div className="md:mx-8 overflow-x-scroll h-full">
     <div className="text-sm font-bold text-xl text-gray-500">
       {events[0] && dayjs(events[0].start).tz().format('D日')}
     </div>

      {activeDate === null && (
        <div className="text-center mt-4">
          日付を選択してください
        </div>
      )}

      {events.length === 0 && activeDate && (
        <div className="text-center mt-4">
          予約空枠はありません
        </div>
      )}

      <ul id="reserves" className="flex flex-wrap mt-4">
        { events.map((event, index) => {
          return (
            <li className="cursor-pointer w-1/3 mb-4 px-2 flex justify-center items-center" key={index} onClick={() => handleEventClick(event)}>
              <div className="border-2 border-gray-500 rounded-full p-4 font-bold text-center w-20 h-20 md:h-24 md:w-24 flex text-center justify-center items-center">
                <div className="text-2xl md:text-3xl mr-1">
                  {dayjs(event.start).tz().format('HH')}
                </div>
                <span className="text-sm">
                  時
                </span>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SimpleReserveEvents
